import React from "react";
import { graphql, Link } from "gatsby";
import styled from 'styled-components';
import { StaticImage } from 'gatsby-plugin-image';

import Layout from "../layouts";
import Seo from "../components/seo";
import {
	BreadCrumb,
	Section,
	SectionLead,
	SectionPageTitle,
	SectionTitle,
	SectionDescription,
	SubTitle
} from '../components/Section'
import BlogCard from "../components/BlogCard";
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton";
import LineArrowRight from "../components/Icons/LineArrowRight";
import AddressIcon from "../components/Icons/AddressIcon";
import PhoneIcon from "../components/Icons/PhoneIcon";
import MailIcon from "../components/Icons/MailIcon";

const BlogList = styled.div`
	width: 100%;
	display: flex;
	justify-content: space-between;
	margin: 56px 0 60px;

	.card {
		width: 32%;
	}

	@media(max-width: 991px) {
		display: block;
		.card {
			width: 100%;
			max-width: 500px;
			margin: auto;
			margin-bottom: 30px;
		}
	}
`

const AddressWrap = styled.div`
	margin-top: 40px;
	padding: 100px 115px;
	background: #ffffff;
	box-shadow: 0 20px 50px rgba(0, 0, 0, .2);
	@media(max-width: 1200px) {
		padding: 100px 50px;
	}
`

const AddressDetails = styled.div`
	display: flex;
	align-items: center;
	justify-content: space-between;
	@media(max-width: 768px) {
		flex-wrap: wrap;
	}
`

const AddressItem = styled.div`
	width: ${props => props.width};
	display: ${props => props.display};
	margin: auto;
	margin-bottom: ${props => props.mb};
	padding-right: ${props => props.pr};
	align-items: center;
	justify-content: center;

	.icon {
		width: 18px;
		height: 20px;
	}

	.phone {
		margin-top: -5px;
	}

	p {
		margin-bottom: 0;
		margin-left: 10px;
		overflow-wrap: anywhere;
		font-family: 'Eina03-Bold';

		a {
			color: #666;
		}
	}
	@media(max-width: 768px) {
		width: 100%;
		justify-content: flex-start;
		padding-right: 0;
	}
`

AddressItem.defaultProps = {
	display: "flex",
	width: "25%",
	pr: '0',
}

const Divider = styled.div`
	width: 1px;
	height: 100px;
	border-right: 1px solid #D0D0D0;
	@media(max-width: 768px) {
		width: 100%;
		height: 1px;
		border-right: 0px;
		border-top: 1px solid #D0D0D0;
		margin: 22px 0;
	}
`

const AddressTitle = styled(SubTitle)`
	margin-left: 5%;

	@media(max-width: 768px) {
		margin-left: 0;
	}
`


const ServiceArea = ({ data, location }) => {
	return(
		<Layout footerCta location={location}>
			<Seo title="Check out all the states we serve - Our Service Area" description="Buildings And More offers services in almost every state in the continental United States. See the details here." />
			<BreadCrumb position="relative">
				<div className="container">
					<Link to="/">Home</Link><span>&nbsp;/&nbsp;</span>
					<span>Service Area</span>
				</div>
			</BreadCrumb>
			<Section pt="50px" pb="0" bgColor="#1E3340">
				<div className="container">
					<SectionLead color="#E43D3C" mb="10px">Service Area</SectionLead>
					<SectionPageTitle color="#ffffff" maxWidth="600px" mt="0" lineHeight="52px">
						Free Delivery & Installation Within the Continental United States
					</SectionPageTitle>
					<SectionDescription maxWidth="900px" color="#ffffff">
						Buildings And More goes to nearly every state of the continental United States. Find out the locations we serve. All Portable Buildings include free delivery within 60 miles of our Lake City Florida location.
					</SectionDescription>
					<StaticImage
						src="../images/state-map.png"
		        placeholder="blurred"
		        formats={["AUTO", "WEBP", "AVIF"]}
		        alt="color-options"
		      />
				</div>
				<StaticImage
					src="../images/map_bottom.png"
	        placeholder="blurred"
	        formats={["AUTO", "WEBP", "AVIF"]}
	        alt="color-options"
	      />
			</Section>
			<Section pt="50px" pb="50px" bgColor="#F7F5F5">
				<div className="container">
					<AddressWrap>
						<SectionTitle mt="0" mb="10px">
							Our Office
						</SectionTitle>
						<SectionDescription mb="50px">
							Our professional staff at Buildings And More is waiting to assist you with your requirement. We are happy to help you with any questions, so pick up your phone and call us now or contact us by any other means mentioned below.
						</SectionDescription>
						<AddressTitle textAlign="left" fontSize="20px">Lake City</AddressTitle>
						<AddressDetails>
							<AddressItem>
								<div className="icon"><AddressIcon fill="#E43D3C" /></div>
								<p><a href="https://maps.app.goo.gl/Uy9Tda6jTpHv2kyF9" target="_blank">792 SW Bascom Norris Drive, Lake City, FL 32025</a></p>
							</AddressItem>
							<Divider />
							<AddressItem display="block">
								<AddressItem width="100%" mb="10px">
									<div className="icon phone"><PhoneIcon fill="#E43D3C" /></div>
									<p><a href="tel:8664361862">(866) 436-1862</a><span> (Toll free)</span></p>
								</AddressItem>
								<AddressItem width="100%" pr="76px">
									<div className="icon phone"><PhoneIcon fill="#E43D3C" /></div>
									<p><a href="tel:3867556449">(386) 755-6449</a></p>
								</AddressItem>
							</AddressItem>
							<Divider />
							<AddressItem>
								<div className="icon"><MailIcon fill="#E43D3C" /></div>
								<p><a href="mailto:info@buildingandmore.com">info@buildingandmore.com</a></p>
							</AddressItem>
						</AddressDetails>
						<AddressTitle textAlign="left" fontSize="20px">Macclenny</AddressTitle>
						<AddressDetails>
							<AddressItem>
								<div className="icon"><AddressIcon fill="#E43D3C" /></div>
								<p><a href="https://maps.app.goo.gl/o22iX8engdeS8rNy9" target="_blank">1168 Macclenny Avenue West, Macclenny, FL 32063</a></p>
							</AddressItem>
							<Divider />
							<AddressItem>
								<div className="icon phone"><PhoneIcon fill="#E43D3C" /></div>
								<p><a href="tel:9042594054">(904) 259-4054</a></p>
							</AddressItem>
							<Divider />
							<AddressItem>
								<div className="icon"><MailIcon fill="#E43D3C" /></div>
								<p><a href="mailto:info@buildingandmore.com">info@buildingandmore.com</a></p>
							</AddressItem>
						</AddressDetails>
						<AddressTitle textAlign="left" fontSize="20px">Starke</AddressTitle>
						<AddressDetails>
							<AddressItem>
								<div className="icon"><AddressIcon fill="#E43D3C" /></div>
								<p><a className="item" href="https://maps.app.goo.gl/DqjPRWSU1aBzaJsR8"  target="_blank">1540 South Walnut Street, Starke, FL 32091</a></p>
							</AddressItem>
							<Divider />
							<AddressItem>
								<div className="icon phone"><PhoneIcon fill="#E43D3C" /></div>
								<p><a href="tel:9049660077">(904) 966-0077</a></p>
							</AddressItem>
							<Divider />
							<AddressItem>
								<div className="icon"><MailIcon fill="#E43D3C" /></div>
								<p><a href="mailto:info@buildingandmore.com">info@buildingandmore.com</a></p>
							</AddressItem>
						</AddressDetails>
					</AddressWrap>
				</div>
			</Section>
			<Section pt="50px" pb="50px" textAlign="center">
				<div className="container">
					<SectionTitle>Explore Our Latest Blogs</SectionTitle>
					<BlogList>
					{
						data.allContentfulBlog.edges.map((item, i) => (i < 3) && (
								<div className="card" key={`blog-${i}`}>
									<BlogCard data={item.node} />
								</div>
							)
						)
					}
					</BlogList>
					<Link to="/blog">
						<PrimaryLinkButton text="Explore all articles" icon={<LineArrowRight />} />
					</Link>
				</div>
			</Section>
		</Layout>
	)
}

export default ServiceArea

export const query = graphql`
  query ServiceAreaQuery {
  	allContentfulBlog {
      edges {
        node {
          title
          description {
            childMarkdownRemark {
              html
            }
          }
          image {
            gatsbyImageData(quality: 80)
          }
          author {
            name
          }
          createdAt(formatString: "MMM DD, yy")
					url
        }
      }
    }
    allContentfulState {
    	edges {
    		node {
    			name
    			url
    		}
    	}
    }
  }
`